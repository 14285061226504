.collapsible {
  margin-bottom: $gap-md;
  &.expanded {
    .content {
      max-height: 100%;
      opacity: 1;
      overflow: visible;
      transition: all 0.05s 0.15s ease;
    }
  }
  .header{
    display: flex;
    padding: 0;
    margin: $gap 0 $gap-base;
    svg {
      width: $gap-sm + ($gap-xs / 2);
      height: $gap-sm + ($gap-xs / 2);
      margin-right: $gap-sm;
      color: lighten($text-color,40%);
    }
    &:hover,
    &:focus {
      svg {
        color: $brand-primary;
      }
    }
  }
  .title {
    font-size: 1rem;
    text-transform: uppercase;
    @include font-semibold;
  }
  .toggle {
    display: flex;
    align-items: center;
    background: none;
    border: 0;
    width: 100%;
    @include font-semibold;
    &:focus {
      @include focus-outline;
    }
  }
  .content {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: all 0.05s 0.15s ease;
  }
  .collapse-toggle {
    background: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    display: flex;
    width: 100%;
    &:focus {
      @include focus-outline;
    }
  }
}
