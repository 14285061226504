// Compiling CSS4 variables can be toggled on or off in _settings.scss

// Color map
$colors: (
  text: $text-color,
  background: $page-bg,
  link: $link-color,
  link-active: darken($link-active, 10%),
  gray-dk: $gray-dk
);

$spacers: (
  size: (
    base: $gap,
    xs: $gap / 4,
    sm: $gap / 2,
    md: $gap * 1.5,
    lg: $gap * 2
  )
);

// Font map
$fonts: (
  family: (
    body: ($font-regular),
    head: ($font-heading)
  ),
  weight: (
    body: $font-weight-regular,
    head: $font-weight-bold,
    semibold: $font-weight-semibold
  ),
  size: (
    body: $font-size-base,
    small: ($font-size-base - 1px),
    h1: $font-size-h1,
    h2: $font-size-h2,
    h3: $font-size-h3,
    h4: $font-size-h4,
    h5: $font-size-h5,
    h6: $font-size-h6
  ),
  leading: (
    body: $line-height-base,
    head: $line-height-large
  )
);


// associate variable map name to an actual variable map
$var-maps: (
  color:   $colors,
  font:    $fonts,
);

// which property uses which var map
$props: (
  background-color: color,
  color: color,
  font-family : (font, family),
  font-size: (font, size),
  font-weight: (font, weight),
  line-height: (font, leading)
);

// the variable mixin takes a property and variable name
@mixin v($prop, $var, $show-fall: true) {
  // get the property's map name(s)
  $map-name: map-get($props, $prop);
  $nest-name: null;
  $nest-map-name: null;
  $map: null;
  $var-fall: null;
  $var-output: null;
  // if a list, we need to go deeper
  @if type-of($map-name)==list {
    $nest-name: nth($map-name, 1);
    $nest-map-name: nth($map-name, 2);
  }
  // if it is a nested map
  @if $nest-name {
    // get the map from nested map-name
    $map: map-get($var-maps, $nest-name);
    // get the nested map
    $nest-map: map-get($map, $nest-map-name);
    // get the var value fro mthe nested map
    $var-fall: map-get($nest-map, $var);
    $var-output: var(--#{$nest-name}-#{$nest-map-name}-#{$var});
  }
  @else {
    // get the map from map name
    $map: map-get($var-maps, $map-name);
    // fallback, grab the variable's value from the map
    $var-fall: map-get($map, $var);
    $var-output: var(--#{$map-name}-#{$var});
  }
  // if show standard is not overridden to be null
  @if $show-fall {
    #{$prop}: $var-fall;
  }
  // css4 variable output
  @if $enable-css-variables==true {
    #{$prop}: $var-output;
  }
}


// setup the css4 variable definitions
@if $enable-css-variables==true {
  :root {

    @each $var-map-name,
    $var-map in $var-maps {

      @each $var,
      $val in $var-map {

        // if it is a map, go another level deep
        @if type-of($val)==map {

          @each $var-n,
          $val-n in $val {
            #{--$var-map-name}-#{$var}-#{$var-n}: $val-n;
          }
        }

        @else {
          #{--$var-map-name}-#{$var}: $val;
        }
      }
    }

    // each item in spacers map
    @each $item,
    $spacing in $spacers {

      // maps require a second loop
      @if type-of($spacing)=="map" {

        // each item in sub map
        @each $subname,
        $subsize in $spacing {
          // --size-viewport-md
          --gap-#{$subname}: #{$subsize};
        }


      }

      @elseif type-of($spacing)=="number" {

        // --size-background
        --size-#{$item}: $spacing;

      }
    }



  }
}


