[class^="container-"] {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: $gap;
  padding-right: $gap;
}

.container-lg {
  max-width: $screen-lg;
}

.container-md {
  max-width: $screen-md;
}

.page-container {
  //min-height: calc(100vh - #{$header-height});
}

.page-content {
  padding: $gap;
  flex: 1;
  &:focus {
    outline: none;
  }
  @media (min-width: $screen-sm) {
    padding-left: $gap-lg;
    padding-right: $gap-lg;
  }
}

.page-title {
  display: flex;
  font-size: 1.625rem;
  text-transform: uppercase;
  margin: 0 0 $gap;
  @include font-medium;
  @media (max-width: $screen-xs) {
    font-size: 1.5rem;
  }
  svg {
    color: lighten($gray-md,15%);
    width: $gap-base;
    height: $gap-base;
    margin-left: $gap-sm;
    margin-top: $gap-xs / 2.75;
  }
}

.d-md-grid {
  display: grid;
  @media (max-width: $screen-md) {
    grid-template-columns: 1fr !important;
    grid-row-gap: $gap-base;
  }
}

.three-col {
  grid-column-gap: $gap-base;
  grid-template-columns: 1fr 1fr 1fr;
  &.small-center {
    grid-template-columns: minmax(300px, 1fr) minmax(200px, 1fr) 360px;
  }
}

.two-col {
  grid-column-gap: $gap-base;
  grid-template-columns: 1fr 1fr;
  &.small-right {
    grid-template-columns: minmax(300px, 1fr) 360px;
  }
}

.link-list {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0 0 $gap-sm;
  margin: 0;
  position: relative;
  .icon-link {
    svg {
      margin-right: $gap-xs;
    }

  }
  a {
    display: inline-flex;
    font-size: $font-size-base;
    position: relative;
    @include font-semibold;
  }
  &.horizontal {
    @media (min-width: $screen-sm) {
      flex-direction: row;
      align-items: center;
      li {
        display: flex;
        align-items: center;
      }
      li+li,
      a+a {
        margin-top: 0;
        margin-left: $gap-sm;


        &::before {
          content:"";
          height: 100%;
          min-height: 40px;
          width: 1px;
          background: $border-default;
          display: flex;
          margin-right: $gap-sm;
        }
      }
    }
  }
  &.vertical {
    li+li,
    a+a {
      margin-top: $gap-xs;
    }
    a {
      &::before {
        content: "";
        background-color: transparent;
        transition: all 0.05s 0.15s ease-in-out;
        margin-left: -$gap-xs * 1.75;
        top: $border-radius * 1;
        position: absolute;
        @include triangle(transparent, right, $border-radius)
      }

      &:hover,
      &:focus,
      &:active {
        &::before {
          @include triangle($brand-primary, right, $border-radius)
        }
      }
    }
  }
}

.control-group {
  display: flex;
  .btn-b+.btn-b {
    margin-top: $gap-xs;
  }
  &:not(.vertical) {
    .btn-b+.btn-b {
      margin-left: $gap-xs;
    }
  }
  &.vertical {
    flex-direction: column;
  }
}

.item-title {
  font-weight: bolder;
  font-size: 14px;
  color: black;
}

.item-value {
  font-weight: 400;
  font-size: 13px;
  color: black;
}

.controls {
  display: flex;
  align-items: flex-start;
  @media (max-width: $screen-xs) {
    width: 100%;
    &.col-auto {
      flex-basis: 100%;
    }
  }
  @media (max-width: $screen-sm-min) {
    flex-direction: column;
  }
  @media (min-width: $screen-sm) {
    justify-content: flex-end;
    .btn-b + .btn-b {
      margin-left: $gap-xs * 1.5;
    }
  }
}


[class^="col-"] > .MuiFormControl-root {
  margin-left: 0;
  margin-right: 0;
  @media (min-width: $screen-sm) {
    margin: 0;
  }
}


.dialog-controls {
  @media (max-width: 767px) {
    margin-left: $gap-sm;
    button {
      width: 100%;
      +  button {
        margin-top: 1rem;
      }
    }
  }
}
