button {
  appearance: none;
  font-family: $font-regular;
}

.btn-b,
button:not([class]) {

  align-items: center;
  background-image: none;
  border: 0;
  cursor: pointer;
  display: inline-flex;
  font-size: $font-size-sm;
  justify-content: center;
  margin: 0;
  min-height: $input-height;
  padding: 0 $gap-sm;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  transition: background border 0.15s ease-in-out;
  @include font-semibold;
}

.btn-b.MuiButton-root {
  @include font-semibold;
  font-size: $font-size-sm;
}

.btn-sm {
  min-height: $input-height * 0.75;
}

.btn-lg {
  min-height: $input-height * 1.25;
  min-width: 8rem;
}
.btn-b.btn-min {
  min-width: 8rem;
}

.btn-full {
  width: 100%;
}

.mobile-fill {
  @media (max-width: $screen-xs) {
    width: 100%;
  }
}

.btn-back {
  align-items: center;
  display: inline-flex;
  font-size: 0.8rem;
  margin-bottom: $gap;
  text-transform: uppercase;
  transition: all 0.15s;
  @include font-bold;
  svg {
    margin-right: $gap-xs;
    transition: all 0.15s;
  }
  &:hover,
  &:focus {
    svg {
      animation: bounce-left 0.5s forwards;
    }
  }
}

.btn-forward {
  align-items: center;
  display: inline-flex;
  font-size: 0.8rem;
  padding-left: 0;
  padding-right: 0;
  text-transform: uppercase;
  @include font-bold;
  svg {
    height: $gap-sm;
    margin-left: $gap-xs;
    width: $gap-sm;
  }
}

.skip-link {
  position: absolute;
  color: white;
  z-index: 100;
  padding: $gap-xs $gap;
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: $one;
  width: $one;
  margin: -$one;
  border: 0;
  &:focus {
    clip: initial;
    height: auto;
    width: auto;
    margin: 0;
    color: white;
  }
}


.btn-group {
  .btn-b {
    min-height: $input-height * 1.25;
    min-width: 8rem;
  }
  .col-auto {
    @media (max-width: $screen-xs) {
      width: 100%;
      .btn-b {
        width: 100%;
      }
      + .col-auto {
        margin-top: $gap-sm;
      }
    }
  }
}
