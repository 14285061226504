.Dashboard {
    .page-loader {
        width: 100%;
        opacity: 0.5;
        height: 100%;
        background-color: rgb(0, 0, 0);
        text-align: center;
        position: fixed;
        z-index: 9999;
        top: 0px;
        left: 0px;
        display: block;
    }
    .makeStyles-checkoutCard-5 {
        h2 {
            font-weight: bold;
        }
        .MuiCardContent-root {
            background-color: blanchedalmond;
            border: 1px solid #ffb300;
        }
    }
}

.MuiPaper-root.MuiDialog-paper {
    min-width: 50% !important;
}

.controls {
    .btn-filled {
        min-width: 100px;
    }
}

.form-row {
    margin-top: 10px;
}

.checkout-helper {
    margin-left: 5%;
    font-weight: bold;
    color: mediumblue;
}

.invalid-feedback {
    font-size: 11px;
}

.checkout {
    background: #eee;
    padding: 2rem;
    button {
        @media (max-width: 767px) {
            margin-top: 1rem;
            width: 100%;
        }
    }
    .intro {
        font-size: 1.2rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
    }

    .description {
        font-size: 1rem;
    }
}
