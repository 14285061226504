// -- Icons
.ic-stroke {
  stroke: currentColor;
}

.ic-fill {
  fill: currentColor;
}

.ic-size_sm {
  height: $gap-sm;
  width: $gap-sm;
}

.ic-size_default {
  height: $gap-base;
  width: $gap-base;
}

.ic-size_md {
  height: $gap-md;
  width: $gap-md;
}

.ic-size_lg {
  height: $gap-lg;
  width: $gap-lg;
}

//-- Text
.t-underline {
  text-decoration: underline;
}

.t-ucase {
  text-transform: uppercase;
}

.t-read {
  max-width: 60rem;
}

.t-align_center {
  text-align: center;
}

.t-align_right {
  text-align: right;
}

.t-size_x-small {
  font-size: $font-size-base - 2px;
}

.t-size_small {
  font-size: $font-size-base - 1px;
}

.t-size_default {
  font-size: 1rem;
}

.t-size_medium {
  font-size: 1.1rem;
}

.t-weight_regular {
  @include font-regular;
}

.t-weight_semibold {
  @include font-semibold;
}

.t-weight_bold {
  @include font-bold;
}

//-- Utilities
.sr-only {
  @include sr-only;
}

.hidden-xs {
  @media (max-width: $screen-xs) {
    @include sr-only;
  }
}

.cursor-pointer {
  cursor:pointer;
}


.xs-only {
  @media (min-width: $screen-xs + 1px) {
    display: none !important;
  }
}
