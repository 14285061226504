*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  position: relative;
  min-height: 100%;
  box-sizing: border-box;
  @include v(font-size, body);
  @media (min-width: $screen-sm) {
    font-size: $font-size-base + 2px;
  }
}

body {
  margin: 0;
  padding: 0;
  @include font-smoothing;
  @include v(font-family, body);
  @include v(line-height, body);
  @include v(background-color, background);
  @include v(color, text, null);
}

a {
  cursor: pointer;
  text-decoration: none;
  @include v(color, link);
  &:hover,
  &:focus,
  &:active {
    @include v(color, link-active);
  }
  &:not([class]) {
    &:hover,&:focus,&:active {
      text-decoration: underline;
    }
  }
}

hr {
  background-color: $hr-bg;
  border: 0;
  display: block;
  height: 1px;
  margin-bottom: $gap;
  margin-top: $gap;
}

article,
aside,
section,
header,
footer,
legend,
label {
  display: block;
}

label {
  @include font-semibold;
}

blockquote {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.1;
  @include v(font-family, head);
  @include v(font-weight, head);
}

h1 {
  @include v(font-size, h1);
}

h2 {
  @include v(font-size, h2);
}

h3 {
  @include v(font-size, h3);
}

h4 {
  @include v(font-size, h4);
}

h5 {
  @include v(font-size, h5);
}

h6 {
  @include v(font-size, h6);
}

