.full-width-textare {
    width: 100% !important;
    min-height: 100px;
    margin: 0px !important;
    text-indent: 12px !important;
    line-height: 30px;
}

.MuiDialogTitle-root h2 {
    text-align: center;
}

.MuiDialogActions-root {
    padding: 15px 20px 15px 6px !important;
}

.MuiButton-root {
    border-radius: 0px !important;
}

.MuiFormControl-root {
    width: 100%;
}

.MuiFormLabel-root {
    font-weight: bold !important;
}

.mark-red {
    color: red;
}

.MuiButton-root:hover {
    background-color: black !important;
}

.invalid-feedback {
    color: red;
}

.MuiInputBase-root textarea {
    min-height: 60px !important;
}

.MuiDialogContent-root {
    padding: 0px 25px 25px 25px !important;
}

.MuiTypography-colorTextSecondary {
    color: rgba(0, 0, 0, 0.84) !important;
    font-size: 13px !important;
}

button:disabled {
    background-color: #d51515c7 !important;
}

.request-food-btn button {
    padding: 0px 20px;
    font-size: 12px !important;
    margin-right: 10px;
}

.MuiButton-root.Mui-disabled {
    color: #FFF !important
}