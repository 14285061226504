@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap');

//-- Prefix for components (string)
$prefix: ".v-";

//-- Prefix for app (string)
$app-prefix: ".THIS ";

//-- Debug your box model (options: true, false)
$enable-debugger: false;

//-- Let CSS4 make your life easier, or don't (options: true, false)
$enable-css-variables: false;

//-- Enable spacer utility classes (options: true, false)
$enable-spacers: true;

//-- Enable flexbox grid system (options: true, false)
$enable-grid-classes: true;

//-- Enable flexbox utility classes (options: true, false)
$enable-flex-classes: true;


//--- Spacing
$one: 0.0625rem;

$spacer: 1rem;
$spacer-x: $spacer;
$spacer-y: $spacer;

$gap-xs: $spacer / 2;
$gap-sm: $spacer;
$gap-base: $spacer * 1.5;
$gap: $spacer * 2;
$gap-md: $spacer * 3;
$gap-lg: $spacer * 4;

//-- Grid settings
$grid-columns: 12;
$grid-gutter-width: $gap-sm+$gap-xs;

//-- Viewports
// 14px base
$screen-xs: 480px;
$screen-sm: 768px;
$screen-sm-min: $screen-sm - 1px;
// 16px base
$screen-sm-ls: 1024px;
$screen-md: 991px;
$screen-lg: 1200px;
$screen-xl: 1440px;


//-- Layout
$header-height: 5rem;
$header-height-collapsed: 4rem;
$sidebar-width: 15rem;
$footer-height: $gap-md;


//- Brand Colors
$gray-lt: #f6f6f6;
$gray: #dddddd;
$gray-md: #757575;
$gray-dk: #343434;

$brand-primary: #C00404;
$brand-secondary: #75BFED;

$success: #93c461;
$info: #5bc0de;
$warning: #f29f4e;
$danger: #e24f44;

//-- Forms
$input-height: $gap+0.5rem;
$touch-input-size: $gap;
$touch-input-color: $gray-md;
$touch-input-color-active: $brand-primary;

//-- Typography
$font-regular: "Montserrat",
sans-serif;
$font-heading: "Montserrat",
sans-serif;
$font-size-base: 14px;

$font-size-sm: $font-size-base - 1px;
$font-size-xs: $font-size-base - 2px;
$font-size-xxs: $font-size-base - 3px;
$font-size-default: $font-size-base+2px;
$font-size-md: $font-size-base+4px;
$font-size-lg: $font-size-base+6px;
$font-size-xl: $font-size-base+8px;

$line-height-small: 1.5;
$line-height-base: 1.428571429;
$line-height-large: 1.3333333;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$font-size-h1: 3.5rem;
$font-size-h2: 2rem;
$font-size-h3: 1.5rem;
$font-size-h4: 1.25rem;
$font-size-h5: $font-size-base;
$font-size-h6: 0.875rem;


//-- Element colors
$text-dark: #000000;
$text-color: $gray-dk;
$text-light: darken($gray, 10%);

$link-color: $brand-primary;
$link-active: black;

$border-default: #d5d5d5;
$border-lt: $gray-lt;
$hr-bg: $gray;

//-- Layout colors
$page-bg: white;

$header-bg: white;
$header-color: $text-color;

$sidebar-bg: #F0F2F3;

$footer-bg: $gray-dk;
$footer-color: lighten($gray-lt, 30%);


//-- Other
$border-radius-sm: 3px;
$border-radius: 5px;
