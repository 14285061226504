.site-header {
  background: $header-bg;
  border-bottom: 2px solid $brand-primary;
  color: $header-color;
  display: flex;
  height: $header-height;
  justify-content: space-between;
  padding: $gap-sm $gap-base;
  .user {
    display: flex;
    align-items: center;
    @media (min-width: $screen-xs) {
      padding-left: $gap-base;
      border-left: 1px solid $gray;
    }
    .name {
      font-size: $font-size-base;
      @include font-bold;
    }
    .person {
      padding-right: $gap-sm;
    }
    svg {
      width: $gap;
      height: $gap;
    }
  }
  .log-out {
    font-size: 0.75rem;
    color: $gray-md;
    @include font-medium;
  }
}

.logo-lockup {
  display: flex;
  .logo {
    width: 5rem;
  }
  .lockup {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: $font-size-base;
    @include font-semibold;
    @media (max-width: $screen-xs) {
      font-size: $font-size-xs - 2px;
      max-width: 160px;
    }
    &::before {
      content: "";
      margin: auto $gap-sm;
      height: 32px;
      width: 2px;
      background: #DBDBDB;
      @media (max-width: $screen-xs) {
        width: 1px;
        margin: auto ($gap-xs * 1.5);
      }
    }
  }
}

.toolbar-nav {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: $gap-base;
  a {
    line-height: 1.2;
    font-size: 0.85rem;
    @include font-medium;
    > span {
      display: block;
    }
  }
}


.info-btn {
  display: flex;
  margin-bottom: $gap;
  align-items: center;
  color: $text-color;

  text-transform: uppercase;
  line-height: 1.3;
  font-size: $gap-sm;
  padding: $gap-base * 1.75;
  @include font-medium;
  svg {
    width: $gap-base;
    height: $gap-base;
    margin-left: auto;
  }
  .txt {
    max-width: 160px;
  }

}
