$touch-icon-size: 1.8rem;

// Avoid iOS browsers from zooming on form element focus.
@media screen and (-webkit-min-device-pixel-ratio:0) and (max-device-width:1024px) {
  input,
  select,
  textarea {
    font-size: $font-size-base;
    &:focus {
      font-size: $font-size-base;
    }
  }
}



.input-group {
  margin-bottom: $gap-sm;
  > label {
    margin-bottom: $gap-xs;
    display: block;
  }
}

.input-text,.form-field {
  //appearance: none;
  background: white;
  font-size: 0.9rem;
  border: 1px solid $border-default;
  border-radius: 0;
  line-height: 1;
  min-height: $gap + ($gap-xs / 2);
  height: $gap + ($gap-xs / 2);
  padding: ($gap-xs * 0.5) $gap-xs;
  width: 100%;
  &.medium {
    min-height: $input-height;
  }
  &.large {
    min-height: $gap + $gap-xs;
  }
}

.radio-block {
  display: flex;
  padding-top: $one;
  position: relative;
  margin-bottom: $gap-sm - $gap-xs;
  &:hover {
    .radio {
      border-color: white;
    }
  }
  input[type=radio]:checked {
    ~ .radio {
      border: 0.2rem solid lighten($brand-primary,15%);
      &::before {
        background: lighten($brand-primary,15%);
      }
    }
    ~ label {
      color: lighten($brand-primary,15%);
    }
  }
}
.radio {
  display: block;
  border: 0.2rem solid white;
  border-radius: 100%;
  height: $touch-icon-size;
  left: 0;
  margin: 0;
  position: absolute;
  top: 0;
  transition: border .25s linear;
  width: $touch-icon-size;
  z-index: 5;
  &::before {
    border-radius: 100%;
    content: '';
    display: block;
    height: $touch-icon-size * 0.5;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
    transition: background 0.15s linear;
    width: $touch-icon-size * 0.5;
  }
}
.radio-label {
  align-items: center;
  display: flex;
  height: $input-height;
  margin: 0;
  padding-left: $touch-icon-size + $gap-xs;
  position: relative;
  z-index: 5;
}

.input-horizontal {
  margin-bottom: $gap-sm;
  position: relative;
  @media (min-width:$screen-sm) {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    > label {
      line-height: 1.2;
      padding-right: $gap;
      width: 10rem;
    }
    input,select,textarea {
      flex: 1;
    }
  }
  label.error {
    margin-left: 10rem;
  }
  .req {
    color: $danger;
    left: -$gap-sm;
    position: absolute;
  }
}


