

.sidebar-layout {
  //min-height: calc(100vh - #{$header-height});

  display: flex;
  .sidebar {
    position: relative;
    .wrapper {
      transition: all 0.15s;
      background: $sidebar-bg;
      padding: $gap-base;
      min-height: calc(100vh - #{$header-height});
      width: 240px;
      height: 100%;
    }
  }
}

.primary-nav {
  display: flex;
  flex-direction: column;
  svg {
    width: 14px;
    height: 14px;
    margin-right: $gap-xs;
  }
  a {
    align-items: center;
    display: flex;
    color: $gray-md;
    padding-top: $gap-xs;
    padding-bottom: $gap-xs;
    white-space: pre;
    &.active {
      color: $text-color;
      @include font-bold;
    }
  }
}

.sidebar-toggle {
  position: absolute;
  width: $gap-base;
  height: $gap-base;
  display: flex;
  justify-content: center;
  align-items: center;
  top: $gap-sm;
  border-top-right-radius: $border-radius-sm;
  border-bottom-right-radius: $border-radius-sm;
  svg {
    height: $gap-xs * 1.25;
    width: $gap-xs * 1.25;
  }
}


@mixin toggle-collapsed {
  right: -$gap-base;
  background: darken($sidebar-bg,10%);
  svg {
    transform: rotate(0);
  }
}
@mixin toggle-expanded {
  right: -($gap-xs * 1.5);
  background: $sidebar-bg;
  svg {
    transform: rotate(180deg);
  }
}

@mixin sidebar-expanded {
  width: 240px;
}

@mixin sidebar-collapsed {
  background: darken($sidebar-bg, 10%);
  width: 4px;
  overflow: hidden;
  padding-left: 0;
  padding-right: 0;
  transition: all 0.15s;
  a {
    color: transparent;
  }
}


@media (max-width: $screen-lg - 1px) {
  .sidebar-toggle-input:not(:checked) {
    ~.sidebar-toggle {
      @include toggle-collapsed;
    }
    ~.wrapper {
      @include sidebar-collapsed;
    }
  }
  .sidebar-toggle-input:checked {
    ~.sidebar-toggle {
      @include toggle-expanded;
    }
  }
}

@media (min-width: $screen-lg) {
  .sidebar-toggle {
    @include toggle-expanded;
  }
  .sidebar-toggle-input:checked {
    ~ .sidebar-toggle {
      @include toggle-collapsed;
    }
    ~ .wrapper {
      @include sidebar-collapsed;
    }
  }
}


