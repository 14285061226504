.login-header {
  //max-width: $screen-xs;
  padding-left: $gap;
  padding-right: $gap;
  .logo {
    max-width: 360px;
    width: 100%;
    margin: $gap-lg auto $gap-sm;
    img {
      width: 100%;
      margin-bottom: $gap-sm;
    }
  }
  .page-title {
    font-size: 1.425rem;
    margin: 0;
    padding: 0;
    @include font-semibold;
  }
}
