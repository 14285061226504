.table-default {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid $border-default;
  tr {
    &:hover {
      td {
        background: #f9f9f9;
      }
    }
  }
  thead {
    @media (max-width:$screen-sm) {
      display:none;
    }
    th {
      color: $gray-md;
      font-weight: $font-weight-semibold;
      font-size: $font-size-base - 2px;
      position: relative;
      text-transform: uppercase;
      text-align: left;
      &.active {
        color: black;
        .arrowactive,svg  {
          color: $brand-primary;
        }
      }
    }
  }
  tbody {
    td {
      color: $gray-dk;
      padding: $gap-xs $gap-sm;
      vertical-align: top;
      font-size: $font-size-base;

    }
  }
  .date {
    @media (min-width:$screen-sm) {
      width: 120px;
    }
  }
  .name {
    @include font-medium;
  }
  &.table-striped tbody tr:nth-of-type(odd) {
    background: rgba(black,0.025);
  }
  th,td {
    padding: $gap-xs $gap-sm;
    border-right: 0;
    border-bottom: 1px solid #ddd;
  }
  @media screen and (max-width: $screen-sm-min) {
    border: 0;
    thead {
      display: none;
    }
    tbody {
      th,td {
        display:block !important
      }
    }
    tr {
      background: white;
      border: $one solid $border-default;
      display: block;
      margin-bottom: $gap-sm;

    }
    td {
      border-bottom: $one solid $border-default;
      border-top: 0 !important;
      font-size: $font-size-base;
      //text-align: right;
      &:last-child {
        border-bottom: 0;
      }
      &:before {
        content: attr(data-label);
        display: block;
        font-size: 12px;
        padding-bottom: 4px;
        text-transform: uppercase;
        @include font-medium;
      }
    }
  }
  .heading-sort {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .heading-sort svg {
    font-size: 13px;
  }
}


.table-default {
  .MuiAccordion-root.MuiPaper-elevation1 {
    box-shadow: none !important;
  }
  .MuiPaper-root {
    background: transparent;
  }
  .MuiAccordionDetails-root {
    padding-left: 0;
  }
  .MuiButtonBase-root {
    min-height: 24px;
    padding-left: 0;
    padding-right: 0;
    &.Mui-expanded {
      min-height: 28px;
    }
  }
  .MuiAccordionSummary-content {
    margin: 0;
    display: inline-flex;
    color: $brand-primary;
    text-decoration: underline;
    text-decoration-style: dotted;
    &.Mui-expanded {
      margin-top: 0;
      margin-bottom: 6px;
    }
  }
}
