// Theme settings
.btn-primary {
  &.btn-filled {
    background-color: $brand-primary;
    color: contrast-color($brand-primary);
  }

  &.btn-outlined {
    background-color: white;
    border: 1px solid $brand-primary;
    color: $brand-primary;
  }
  &:hover,
  &:focus,
  &:active,
  &.active {
    background-color: black;
    color: contrast-color(black);
    border-color: transparent;
  }
}

.btn-default {
  background-color: white;
  color: contrast-color(white);
  &:hover,&:focus {
    background-color: darkenb(white,20%);
    color: contrast-color(darken(white,20%));
  }
  &:active,&.active {
    background-color: darken(white,25%);
    color: contrast-color(darken(white,25%));
  }
}
