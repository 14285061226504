.card {
  background: #FFFFFF;
  border-radius: $border-radius;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.20);
  padding: $gap-base;
  > .card {
    margin: $gap (-$gap-base) (-$gap-base);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.card-header {
  padding-bottom: $gap-sm;
  .card-title {
    font-size: $font-size-h4 - .125rem;
    margin: 0;
    padding: 0;
    @include font-semibold;
  }
}

.stat-item {
  .value {
    font-size: 40px;
    line-height: 1.2;
    @include font-bold;
  }
  .label {
    font-size: 11px;
    text-transform: uppercase;
    color: $gray-md;
    @include font-bold;
  }
}
